import { render, staticRenderFns } from "./Abroad.vue?vue&type=template&id=21367a61&scoped=true&"
import script from "./Abroad.vue?vue&type=script&lang=js&"
export * from "./Abroad.vue?vue&type=script&lang=js&"
import style0 from "./Abroad.vue?vue&type=style&index=0&id=21367a61&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21367a61",
  null
  
)

export default component.exports